const link = {
    starter: {
        home: '/browse',
        about: '/about',
        login: '/login',
        register: '/register',
        contact: '/contact',
        card: '/collection',
        product: '/collection/products',
        productDetail: '/collection/detail-product',
        pricing: '/utils/pricing',
        faq: '/utils/faq',
        maintenance: '/utils/maintenance',
        comingSoon: '/utils/coming-soon',
        collections: '/collections/connected',
        myaccount: '/my-account',
        search: '/search',
        ghostStart: '/start',
        ghostChat: '/ghost',
        speedPlay: '/auto',
        // onboarding: '/onboarding',
        // questions: '/questions',
    },
};

export default link;
